import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// Order column render function
const OrderColumn = ({ row }: { row: any }) => {
  return (
    <Link to="/apps/ecommerce/order/details" className="text-body fw-bold">
      {row.original.id}
    </Link>
  );
};

// OrderDate column render function
const OrderDateColumn = ({ row }: { row: any }) => {
  return <>{new Date(row.original.created_at).toLocaleDateString()}</>;
};

// Total column render function
const TotalColumn = ({ row }: { row: any }) => {
  return <>${row.original.payment_amount}</>;
};

// PaymentStatus column render function
const PaymentStatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success":
            row.original.payment_status === "succeeded",
          "bg-soft-danger text-danger":
            row.original.payment_status === "failed",
          "bg-soft-info text-info": row.original.payment_status === "pending",
        })}
      >
        {row.original.payment_status === "succeeded" && (
          <i className="mdi mdi-check me-1"></i>
        )}
        {row.original.payment_status === "failed" && (
          <i className="mdi mdi-cancel me-1"></i>
        )}
        {row.original.payment_status === "pending" && (
          <i className="mdi mdi-timer-sand me-1"></i>
        )}
        {row.original.payment_status}
      </span>
    </h5>
  );
};

// PaymentMethod column render function
const PaymentMethodColumn = ({ row }: { row: any }) => {
  return <>{row.original.payment_method}</>;
};

// Status column render function
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-success": row.original.orderStatus === "Delivered",
          "bg-danger": row.original.orderStatus === "pending",
          "bg-info": row.original.orderStatus === "Shipped",
          "bg-warning": row.original.orderStatus === "Processing",
        })}
      >
        {row.original.orderStatus}
      </span>
    </h5>
  );
};

// const navigate = useNavigate();
// navigate("/apps/ecommerce/assign-order-detail", {
//   state: { order: row.original },
// });
{/* <button
onClick={() => handleEditClick(item)}
className="btn btn-xs btn-light"
>
<i className="mdi mdi-pencil"></i> Edit
</button> */}
const ActionColumn = ({ row }: { row: any }) => {
  

  const handleViewClick = async() => {

    const Response = await fetch(
      `https://backend.inkox.com/api/orderStatus/${row.original.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ orderStatus: "Prepration For Production" }),
      }
    );

    if (Response) {
      alert("This Order Is Preparing For Production");
    } else if (!Response) {
      alert("Error While Updating");
    }
   
  };

  return (
    <Button  className="btn btn-primary" onClick={handleViewClick}>
      Start Design
    </Button>
  );
};


const ViewColumn = ({ row }: { row: any }) => {
  const navigate = useNavigate();

  const handleViewClick = async() => {
navigate("/apps/ecommerce/assign-order-detail", {
  state: { order: row.original },
});
   
  };

  return (
    <button
    onClick={handleViewClick}
    className="btn btn-xs btn-primary"
    >
    <i className="mdi mdi-eye"></i> 
    </button>
  );
};


// Function to get columns with edit handler
const getColumns = (handleEditClick: (id: number) => void, handleDeleteClick: (id: number) => void) => [
  {
    Header: "Order No",
    accessor: "id",
    Cell: OrderColumn,
  },
  {
    Header: "Product Image",
    accessor: "productImage",
    Cell: ({ row }: { row: any }) => (
      <>
        {row.original.cartData && row.original.cartData.length > 0 && row.original.cartData[0].imagePreviews
          ? row.original.cartData[0].imagePreviews.map((image: any, index: number) => (
              <img
                key={index}
                src={image.preview}
                alt={`Product ${image.part}`}
                style={{ width: "100px", height: "100px", marginRight: "5px" }}
              />
            ))
          : "No Image Available"}
      </>
    ),
  },
  {
    Header: "order Type",
    accessor: "orderType",
    Cell: ({ row }: { row: any }) =><>
    {row.original.cartData && row.original.cartData.length > 0
      ? `${row.original.cartData[0].orderType}`
      : "Not Found"}
  </>,
  },
  // {
  //   Header: "Name",
  //   accessor: "firstName",
  //   Cell: ({ row }: { row: any }) => <>{row.original.firstName} {row.original.lastName}</>,
  // },
  // {
  //   Header: "Unit Price",
  //   accessor: "unitPrice",
  //   Cell: ({ row }: { row: any }) => <>
  //   {row.original.cartData && row.original.cartData.length > 0
  //     ? `${row.original.cartData[0].unitPrice}`
  //     : "Not Found"}
  // </>,
  // },
  // {
  //   Header: "Total Price",
  //   accessor: "totalPrice",
  //   Cell: ({ row }: { row: any }) => <>
  //   {row.original.cartData && row.original.cartData.length > 0
  //     ? `$${row.original.cartData[0].total_price_with_delivery}`
  //     : "N/A"}
  // </>,
  // },

  {
    Header: "View",
    accessor: "view",
    Cell: ViewColumn,
  },

  {
    Header: "Start Design",
    accessor: "startdesign",
    Cell: ActionColumn,
  },

  {
    Header: "Status",
    accessor: "Status",
    Cell: ({ row }: { row: any }) => <>{row.original.Status}</>
  },

  // {
  //   Header: "Delivery Name",
  //   accessor: "deliveryFirstName",
  //   Cell: ({ row }: { row: any }) => <>{row.original.deliveryFirstName} {row.original.deliveryLastName}</>,
  // },
  // {
  //   Header: "Country",
  //   accessor: "country",
  //   Cell: ({ row }: { row: any }) => <>{row.original.country}</>,
  // },
  // {
  //   Header: "Address",
  //   accessor: "address",
  //   Cell: ({ row }: { row: any }) => <>{row.original.address}</>,
  // },
  // {
  //   Header: "City",
  //   accessor: "city",
  //   Cell: ({ row }: { row: any }) => <>{row.original.city}</>,
  // },
  // {
  //   Header: "State",
  //   accessor: "state",
  //   Cell: ({ row }: { row: any }) => <>{row.original.state}</>,
  // },
  // {
  //   Header: "Zip Code",
  //   accessor: "zip",
  //   Cell: ({ row }: { row: any }) => <>{row.original.zip}</>,
  // },
  // {
  //   Header: "Order Status",
  //   accessor: "orderStatus",
  //   Cell: StatusColumn,
  // },
  // {
  //   Header: "Payment Status",
  //   accessor: "payment_status",
  //   Cell: PaymentStatusColumn,
  // },
  // {
  //   Header: "Payment Method",
  //   accessor: "payment_method",
  //   Cell: PaymentMethodColumn,
  // },
  // {
  //   Header: "Total Amount",
  //   accessor: "payment_amount",
  //   Cell: TotalColumn,
  // },
];

// Pagination size options
const sizePerPageList = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "50",
    value: 50,
  },
];

const AssignOrder = () => {
  const [orderList, setOrderList] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null); // Selected row id
  const [paymentStatus, setPaymentStatus] = useState<string>(""); // Selected payment status
  const [orderStatus, setOrderStatus] = useState<string>(""); // Selected order status
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState<number | null>(null);

  // Fetch the orders from API using fetch
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/place-orders");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrderList(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  // Handle edit button click
  const handleEditClick = (orderId: number) => {
    const selectedOrder: any = orderList.find((order: any) => order.id === orderId);
    if (selectedOrder) {
      setSelectedOrderId(orderId);
      setPaymentStatus(selectedOrder.payment_status);
      setOrderStatus(selectedOrder.orderStatus);
      setShowModal(true);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrderId(null);
  };

  // Handle form submission to update payment and order status
  const handleUpdateStatus = async () => {
    if (selectedOrderId) {
      try {
        const response = await fetch(`https://backend.inkox.com/api/place-orders/${selectedOrderId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_status: paymentStatus,
            orderStatus: orderStatus,
          }),
        });

        if (response.ok) {
          alert("Order updated successfully!");
          setShowModal(false);
        } else {
          alert("Failed to update the order.");
        }
      } catch (error) {
        console.error("Error updating order:", error);
        alert("An error occurred while updating the order.");
      }
    }
  };

  const handleDeleteClick = (orderId: number) => {
    setDeleteOrderId(orderId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteOrderId(null);
  };

  const handleDelete = async () => {
    if (deleteOrderId) {
      try {
        const response = await fetch(`https://backend.inkox.com/api/place-orders/${deleteOrderId}`, {
          method: "DELETE",
        });

        if (response.ok) {
          alert("Order deleted successfully!");
          setOrderList(orderList.filter((order: any) => order.id !== deleteOrderId));
          handleCloseDeleteModal();
        } else {
          alert("Failed to delete the order.");
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        alert("An error occurred while deleting the order.");
      }
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Assign Orders", path: "/apps/ecommerce/orders", active: true },
        ]}
        title={"Assign Orders"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={8}>
                  <form className="row gy-2 gx-2 align-items-center justify-content-lg-start justify-content-between">
                    <div className="col-auto">
                      <div className="d-flex align-items-center w-auto">
                        <label htmlFor="status-select" className="me-2">
                          Status
                        </label>
                        <select className="form-select" id="status-select">
                          <option value="All">All</option>
                          <option value="Paid">Paid</option>
                          <option value="Authorization">
                            Awaiting Authorization
                          </option>
                          <option value="Failed">Payment failed</option>
                          <option value="Unpaid">Unpaid</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </Col>

                {/* <Col lg={4}>
                  <div className="text-lg-end mt-xl-0 mt-2">
                    <Button className="btn btn-danger mb-2 me-2">
                      <i className="mdi mdi-basket me-1"></i> Add New Order
                    </Button>
                    <Button className="btn btn-light mb-2">Export</Button>
                  </div>
                </Col> */}
              </Row>

              <Table
                columns={getColumns(handleEditClick, handleDeleteClick)} // Pass the edit button handler to the table
                data={orderList}
                pageSize={10}
                sizePerPageList={sizePerPageList} // Pagination size options
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for updating status */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Payment Status</Form.Label>
              <Form.Select
                value={paymentStatus}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setPaymentStatus(e.target.value)}
              >
                <option value="">Select Payment Status</option>
                <option value="succeeded">Succeeded</option>
                <option value="failed">Failed</option>
                <option value="pending">Pending</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Order Status</Form.Label>
              <Form.Select
                value={orderStatus}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setOrderStatus(e.target.value)}
              >
                <option value="">Select Order Status</option>
                <option value="Delivered">Delivered</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Shipped">Shipped</option>
                <option value="Processing">Processing</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateStatus}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignOrder;
