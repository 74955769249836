import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import FileUploader from "../../../components/FileUploader";
import './style.css';


interface Product {
  id: number;
  name: string;
  description: string;
  productColor: string;
  productSize: string[];
}

interface GuideBox {
  guidelineBoxText: string;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
}

interface Part {
  name: string;
  [key: string]: any;
  colors: string[];
}

interface FormValues {
  name: string;
  parts: Part[];
}
const ToolApparel: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productColors, setProductColors] = useState<string[]>([]);
  const [screen, setScreen] = useState<boolean>(false);
  const [emb, setEmb] = useState<boolean>(false);
  const [selectedCanvasStatus, setSelectedCanvasStatus] = useState<string>("all");
  const [searchValue, setSearchValue] = useState<string>("");
  const [productsPerPage, setProductsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectProduct, setSelectProduct] = useState<number | null>(null);


  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Project Name"),
    })
  );

  const methods = useForm<FormValues>({ resolver: schemaResolver });
  const { handleSubmit, register, control, formState: { errors } } = methods;

  useEffect(() => {
    if (selectedOption === "Images") {
      fetchProducts();
    }
  }, [selectedOption]);

  const fetchProducts = async () => {
    try {
      let url = `https://backend.inkox.com/api/product?page=${currentPage}&per_page=${productsPerPage}`;
  
      if (selectedCanvasStatus !== "all") {
        url += `&assignproduct=${selectedCanvasStatus}`;
      }
  
      if (searchValue) {
        url += `&name=${searchValue}`;
      }
  
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setProducts(data.data);
      setTotalPages(data.last_page);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage, selectedCanvasStatus, searchValue, productsPerPage]);
  

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    setParts([]);
    setSelectedProduct(null);
    setProductColors([]);
  };

  const handleProductSelect = (productId: number) => {
    const product = products.find((p) => p.id === productId) || null;
    setSelectedProduct(product);
    if (product) {
      const colors = Object.keys(JSON.parse(product.productColor || "{}"));
      console.log("ProductColors",colors);
      setProductColors(colors);
      propagateColorsToParts(colors);
    }
  };

  const propagateColorsToParts = (colors: string[]) => {
    setParts((prevParts) =>
      prevParts.map((part) => ({
        ...part,
        colors,
      }))
    );
  };

  const handleCheckboxChange = (
    partIndex: number,
    option: "screenPrinting" | "embroidery"
  ) => {
    setParts((prevParts) =>
      prevParts.map((part, index) =>
        index === partIndex
          ? { ...part, [option]: !part[option] }
          : part
      )
    );


    if (option === "screenPrinting") {
      setScreen(true); // Toggle `screen`
    } else if (option === "embroidery") {
      setEmb(true); // Toggle `emb`
    } else {
      setScreen(true);
      setEmb(true);
    }

  }
  

  // const handleCheckboxChange = (
  //   partIndex: number,
  //   option: "screenPrinting" | "embroidery"
  // ) => {
  //   setParts((prevParts) =>
  //     prevParts.map((part, index) => {
  //       if (index === partIndex) {
  //         const updatedPart = { ...part, [option]: !part[option] };
  
  //         // Ensure the relevant data structure is initialized
  //         if (option === "screenPrinting" && updatedPart.screenPrinting) {
  //           setScreen(true);
  //           updatedPart.screenPrintingData = updatedPart.screenPrintingData || {
  //             [`ProductImageComponent${partIndex + 1}`]: { image: null, colors: [] },
  //             [`DesignAreaComponent${partIndex + 1}`]: {
  //               designAreaText: "",
  //               designableArea: {
  //                 leftPercent: 0,
  //                 topPercent: 0,
  //                 widthPercent: 0,
  //                 heightPercent: 0,
  //               },
  //               guideboxes: [
  //                 {
  //                   guidelineBoxText: "",
  //                   leftPercent: 0,
  //                   topPercent: 0,
  //                   widthPercent: 0,
  //                   heightPercent: 0,
  //                 },
  //               ],
  //             },
  //           };
  //         }
  
  //         if (option === "embroidery" && updatedPart.embroidery) {
  //           setEmb(true);
  //           updatedPart.embroideryData = updatedPart.embroideryData || {
  //             [`ProductImageComponent${partIndex + 1}`]: { image: null },
  //             [`DesignAreaComponent${partIndex + 1}`]: {
  //               designAreaText: "",
  //               designableArea: {
  //                 leftPercent: 0,
  //                 topPercent: 0,
  //                 widthPercent: 0,
  //                 heightPercent: 0,
  //               },
  //             },
  //           };
  //         }
  
  //         return updatedPart;
  //       }
  //       return part;
  //     })
  //   );
  // };
  

  
  
  // const handleAddPart = () => {
  //   const partIndex = parts.length + 1;
  //   setParts([
  //     ...parts,
  //     {
  //       name: "",
  //       // screenPrinting: false, // Default to unchecked
  //       // embroidery: false, // Default to unchecked
  //       [`ProductImageComponent${partIndex}`]:
  //         selectedOption === "Masking"
  //           ? { image: "" }
  //           : { colors: [] },
  //       [`CanvasComponent${partIndex}`]: {
  //         canvasSize: "dynamic",
  //       },
  //       [`DesignAreaComponent${partIndex}`]: {
  //         designAreaText: "",
  //         designableArea: {
  //           leftPercent: 0,
  //           topPercent: 0,
  //           widthPercent: 0,
  //           heightPercent: 0,
  //         },
  //         guideboxes: [
  //           {
  //             guidelineBoxText: "",
  //             leftPercent: 0,
  //             topPercent: 0,
  //             widthPercent: 0,
  //             heightPercent: 0,
  //           },
  //         ],
  //       },
  //       workingArea: {
  //         leftPercent: 0,
  //         topPercent: 0,
  //         widthPercent: 0,
  //         heightPercent: 0,
  //       },
  //       colors: productColors,
  //     },

      
  //   ]);
  // };


  // const handleAddPart = () => {
  //   const partIndex = parts.length + 1; // Increment part index based on current parts
  //   setParts([
  //     ...parts,
  //     {
  //       name: `Part ${partIndex}`, // Dynamic name for each part
  //       screenPrinting: false, // Default unchecked for screen printing
  //       embroidery: false, // Default unchecked for embroidery
  //       [`ProductImageComponent${partIndex}`]:
  //         selectedOption === "Masking"
  //           ? { image: "" }
  //           : { colors: [] },
  //       screenPrintingData: {
  //         [`CanvasComponent${partIndex}`]: {
  //           canvasSize: "dynamic",
  //         },
  //         [`DesignAreaComponent${partIndex}`]: {
  //           designAreaText: "",
  //           designableArea: {
  //             leftPercent: 0,
  //             topPercent: 0,
  //             widthPercent: 0,
  //             heightPercent: 0,
  //           },
  //           guideboxes: [
  //             {
  //               guidelineBoxText: "",
  //               leftPercent: 0,
  //               topPercent: 0,
  //               widthPercent: 0,
  //               heightPercent: 0,
  //             },
  //           ],
  //         },
  //       },
  //       embroideryData: {
  //         [`CanvasComponent${partIndex}`]: {
  //           canvasSize: "dynamic",
  //         },
  //         [`DesignAreaComponent${partIndex}`]: {
  //           designAreaText: "",
  //           designableArea: {
  //             leftPercent: 0,
  //             topPercent: 0,
  //             widthPercent: 0,
  //             heightPercent: 0,
  //           },
  //           guideboxes: [
  //             {
  //               guidelineBoxText: "",
  //               leftPercent: 0,
  //               topPercent: 0,
  //               widthPercent: 0,
  //               heightPercent: 0,
  //             },
  //           ],
  //         },
  //       },
  //       colors: productColors,
  //     },
  //   ]);
  // };

  const handleAddPart = () => {
    const partIndex = parts.length + 1; // Determine the next part index
  
    const newPart = {
      name: `Part ${partIndex}`, // Dynamic name for each part
      screenPrinting: false, // Default unchecked for screen printing
      embroidery: false, // Default unchecked for embroidery
      [`ProductImageComponent${partIndex}`]:
          selectedOption === "Masking"
            ? { image: "" }
            : { colors: [] },
      screenPrintingData: {
        [`CanvasComponent${partIndex}`]: {
          canvasSize: "dynamic",
        },
        [`DesignAreaComponent${partIndex}`]: {
          designAreaText: "",
          designableArea: {
            leftPercent: 0,
            topPercent: 0,
            widthPercent: 0,
            heightPercent: 0,
          },
          guideboxes: [
            {
              guidelineBoxText: "",
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
          ],
        },
      },
      embroideryData: {
        [`CanvasComponent${partIndex}`]: {
          canvasSize: "dynamic",
        },
        [`DesignAreaComponent${partIndex}`]: {
          designAreaText: "",
          designableArea: {
            leftPercent: 0,
            topPercent: 0,
            widthPercent: 0,
            heightPercent: 0,
          },
          guideboxes: [
            {
              guidelineBoxText: "",
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
          ],
        },
      },
      colors: productColors,
    };
  
    // Add the new part to the list
    setParts((prevParts) => [...prevParts, newPart]);
  };
  

  
  const handleFileUpload = (file: File, partIndex: number, color?: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const partKey = `ProductImageComponent${partIndex + 1}`;
  
      // Ensure the part has the ProductImageComponent initialized
      if (!updatedParts[partIndex][partKey]) {
        updatedParts[partIndex][partKey] = { colors: [], image: "" };
      }
  
      if (color) {
        // Assign the file to the specific color
        updatedParts[partIndex][partKey][color] = file;
  
        // Add the color to the colors array if not already present
        if (!updatedParts[partIndex][partKey].colors.includes(color)) {
          updatedParts[partIndex][partKey].colors.push(color);
        }
      } else {
        // Assign the file directly to `image` for single upload
        updatedParts[partIndex][partKey].image = file;
      }
      console.log("updatedParts",updatedParts);
      return updatedParts; // Return the updated parts array
    });
  
    // Update the files state
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[partIndex] = file;
      return updatedFiles;
    });
  };
  
  
  
  
  
  


  // New function to handle part removal
  const handleRemovePart = (index: number) => {
    const updatedParts = [...parts];
    updatedParts.splice(index, 1); // Remove the part at the specified index
    setParts(updatedParts);
  };

  const handleAddGuideBox = (partIndex: number,datavalue: string) => {
    const updatedParts = [...parts];
    const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
    console.log("------------");
    console.log(partIndex);
    console.log(updatedParts[partIndex]);
    console.log(updatedParts[partIndex][datavalue][designAreaKey].guideboxes);
    updatedParts[partIndex][datavalue][designAreaKey].guideboxes.push({
      guidelineBoxText: "",
      leftPercent: 0,
      topPercent: 0,
      widthPercent: 0,
      heightPercent: 0,
    });
    console.log("Parts",updatedParts)
    setParts(updatedParts);
  };

  

  // const onSubmit: SubmitHandler<FormValues> = async (data) => {
  //   const encodeFileToBase64 = (file: File): Promise<string | null> => {
  //     return new Promise((resolve, reject) => {
  //       if (!(file instanceof File)) {
  //         resolve(null);
  //         return;
  //       }
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result as string);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   };
  
  //   const updatedParts = await Promise.all(
  //     data.parts.map(async (part, index) => {
  //       const partKey = `ProductImageComponent${index + 1}`;
  //       const productImageComponent: Record<string, string | null> = {};
  //       const colors: string[] = parts[index][partKey]?.colors || [];
  
  //       if (parts[index][partKey]) {
  //         const colorImages = parts[index][partKey];
  //         for (const color of colors) {
  //           const file = colorImages[color];
  //           if (file instanceof File) {
  //             productImageComponent[color] = await encodeFileToBase64(file);
  //           }
  //         }
  //       }
  
  //       // Dynamically set the image or colors in the part object based on the selected option
  //       let imageOrColors = null;
  //       if (selectedOption === "Masking") {
  //         imageOrColors = {
  //           image: JSON.stringify(colors), // Set image-related data
  //         };
  //       } else if (selectedOption === "Images") {
  //         imageOrColors = {
  //           colors: productImageComponent, // Set colors-related data
  //         };
  //       }
  
  //       return {
  //         name: part.name,
  //         description: JSON.stringify(part),
  //         ...imageOrColors,
  //       };
  //     })
  //   );
  
  //   const jsonData = {
  //     name: data.name,
  //     parts: updatedParts, // Use the array directly
  //     type: selectedOption,
  //   };
  
  //   console.log("JSON payload:", jsonData);
  
  //   try {
  //     const response = await fetch("https://backend.inkox.com/api/tool-apparels", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(jsonData), // Stringify the full payload
  //     });
  
  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       console.error("Error response from API:", response.status, errorText);
  //       throw new Error(`Error: ${response.status}`);
  //     }
  
  //     const result = await response.json();
  //     alert("CanvasID Created Successfully");
  //     console.log("tool-apparels saved:", result);
  //   } catch (error) {
  //     alert("Error saving CanvasID");
  //     console.error("Error saving tool-apparels:", error);
  //   }
  // };

  const handleRemoveGuideBox = (partIndex: number, boxIndex: number, dataValue: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
      const guideboxes = updatedParts[partIndex][dataValue][designAreaKey].guideboxes;
  
      // Remove the specific guidebox
      guideboxes.splice(boxIndex, 1);
  
      return updatedParts;
    });
  };
  
  
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const encodeFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof File)) {
          resolve(null);
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };
  
    const updatedParts = await Promise.all(
      data.parts.map(async (part, index) => {
        const partKey = `ProductImageComponent${index + 1}`;
        const productImageComponent: Record<string, string | null> = {};
        const colors: string[] = parts[index][partKey]?.colors || [];
  
        if (parts[index][partKey]) {
          const colorImages = parts[index][partKey];
          for (const color of colors) {
            const file = colorImages[color];
            if (file instanceof File) {
              productImageComponent[color] = await encodeFileToBase64(file);
            }
          }
        }

        let imageOrColors = null;
              if (selectedOption === "Masking") {
                console.log("updatedParts",files);
                // Ensure the image is specific to the part
      const file = parts[index][partKey]?.image;
      imageOrColors = {
        image: file ? await encodeFileToBase64(file) : null, // Encode the single image
      };
              } else if (selectedOption === "Images") {
                imageOrColors = {
                  colors: productImageComponent, // Set colors-related data
                };
              }
        
              console.log("Part:", part.name, "Screen Printing:", part.screenPrinting, "Embroidery:", part.embroidery);

        // Determine canvasType
        let canvasType = "";
        if (screen && emb) {
          canvasType = "screenPrinting, embroidery";
        } else if (screen) {
          canvasType = "screenPrinting";
        } else if (emb) {
          canvasType = "embroidery";
        }
  
      
      
  console.log("updatedParts",imageOrColors);
        // Combine screenPrintingObject and embroideryObject into description
        const description = JSON.stringify({
          screenPrinting: part.screenPrintingData || null,
          embroidery: part.embroideryData || null,
        });
  
        return {
          name: part.name,
          description, // Save the combined object as a string
          canvasType,
          productId: selectedProduct?.id || null, 
          ...imageOrColors,

        };
      })
    );
  
    const jsonData = {
      name: data.name,
      parts: updatedParts,
      type: selectedOption,
    };
  
    console.log("JSON payload:", jsonData);
  
    // Post to the API
    try {
      const response = await fetch("https://backend.inkox.com/api/tool-apparels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response from API:", response.status, errorText);
        throw new Error(`Error: ${response.status}`);
      }
  
      const result = await response.json();
      alert("CanvasID Created Successfully");
      console.log("tool-apparels saved:", result);
    } catch (error) {
      alert("Error saving CanvasID");
      console.error("Error saving tool-apparels:", error);
    }
  };
  
  
  
  
  
  
  
  
  
  // const onSubmit: SubmitHandler<FormValues> = async (data) => {
  //   console.log(data);
  
  //   const encodeFileToBase64 = (file: File): Promise<string | null> => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result as string);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   };
  
  //   const updatedParts = await Promise.all(
  //     data.parts.map(async (part, index) => {
  //       const file = files[index];
  //       const base64Image = file ? await encodeFileToBase64(file) : null;
  
  //       return {
  //         name: part.name,
  //         description: JSON.stringify(part),
  //         image: base64Image,
  //       };
  //     })
  //   );
  
  //   const jsonData = {
  //     name: data.name,
  //     parts: updatedParts,
  //     type: selectedOption,
  //   };
  
  //   console.log("JSON payload:", jsonData);
  
  //   try {
  //     const response = await fetch('https://backend.inkox.com/api/tool-apparels', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(jsonData),
  //     });
  
  //     // Check if the response is OK
  //     if (!response.ok) {
  //       // If it's not, log the error status and message
  //       const errorText = await response.text(); // Get the response as text (which might be HTML)
  //       console.error('Error response from API:', response.status, errorText);
  //       throw new Error(`Error: ${response.status}`);
  //     }
  
  //     const result = await response.json();
  //     alert("CanvasID Created Succesfully");
  //     console.log('tool-apparels saved:', result);
  //   } catch (error) {
  //     alert("Error saving CanvasID");
  //     console.error('Error saving tool-apparels:', error);
  //   }
  // };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchValue(searchValue);
  

    if (searchValue === "") {
      // Clear selected product if search is cleared
      setSelectedProduct(null);
      return;
    }
    // Automatically select the first matching product
    const matchingProduct = products.find((product) =>
      product.name.toLowerCase().includes(searchValue)
    );
  
    if (matchingProduct) {
      setSelectedProduct(matchingProduct); // Set the full product object
      handleProductSelect(matchingProduct.id);
    } else {
      setSelectedProduct(null); // Reset selection if no match is found
      handleProductSelect(0);
    }
  };
  


  // Handle canvas status change
  const handleCanvasStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCanvasStatus(e.target.value);
    setCurrentPage(1);
  };

  // Handle pagination change


  // Handle products per page change
  const handleProductsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing products per page
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Canvas Id",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Canvas Id"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Apparel
                </h5>
                <FormInput
                  name="name"
                  label="Product Canvas Id"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={(e) => setProductCanvasId(e.target.value)}
                />

                {/* Only show options when Canvas ID is entered */}
                {productCanvasId && (
                  <div className="mb-3">
                    <Form.Check
                      type="radio"
                      label="Single Color Garment"
                      name="option"
                      value="Masking"
                      checked={selectedOption === "Masking"}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Multi Color Garment"
                      name="option"
                      value="Images"
                      checked={selectedOption === "Images"}
                      onChange={handleOptionChange}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>


        {selectedOption === "Masking" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleAddPart}
                  >
                    Add Part
                  </button>
                  {parts.map((part, index) => {
          const partIndex = index + 1;
          return (
            <Row key={index}>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Part {partIndex}
                    </h5>
                    <FormInput
                      name={`parts[${index}].name`}
                      label="Part Name"
                      placeholder="e.g : Front"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                    />


                    {/* Product Image Upload */}
                    <Form.Group className="mb-3 ">
                      <Form.Label>Product Image {partIndex}</Form.Label>
                      <FileUploader
                        onFileUpload={(files: any) => handleFileUpload(files[0], index)}
                      />
                    </Form.Group>



                   <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}

  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}

  className="mb-3"
/>

{/* Conditionally Render Screen Printing Design & Guideline Areas */}
{part.screenPrinting && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.screenPrintingData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center mt-2">
  <button
    type="button"
    className="btn btn-danger btn-sm"
    onClick={() => handleRemoveGuideBox(index, boxIndex, 'screenPrintingData')}
  >
    Remove Guidebox
  </button>
 
</div>

                        </div>
                      )
                    )}

            
<button
    type="button"
    className="btn btn-secondary btn-sm"
    onClick={() => handleAddGuideBox(index, 'screenPrintingData')}
  >
    Add Guidebox
  </button>
  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{part.embroidery && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo" style={{ display: "none" }}>
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                value={0}
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    {/* <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      style={{ display: "none" }}
                      onClick={() => handleAddGuideBox(index)}
                    >
                      Add Guidebox
                    </button> */}
                   

  </>
)}




                    {parts.length > 0 && productCanvasId && (
  <div className="text-end mb-3">
    <button
      type="button"
      className="btn w-sm btn-primary waves-effect waves-light me-2"
      onClick={handleAddPart}
    >
      Add Part
    </button>
    <button
      type="button"
      className="btn w-sm btn-danger waves-effect waves-light me-2"
      onClick={() => handleRemovePart(index)}
    >
      Remove Part
    </button>
  </div>
)}

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}


                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* Product with Images */}
        {selectedOption === "Images" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>

                <div className="d-flex align-items-center flex-wrap gap-3">
  {/* Search */}
  <div className="me-3">
    <input
      type="search"
      className="form-control my-1 my-lg-0"
      placeholder="Search by Name or Style..."
      onChange={handleSearchChange}
    />
  </div>

  {/* Canvas ID Status filter */}
  <div className="d-flex align-items-center me-sm-3">
    <label htmlFor="canvas-status-select" className="me-2">Canvas Status</label>
    <select
      className="form-select my-1 my-lg-0"
      id="canvas-status-select"
      value={selectedCanvasStatus}
      onChange={handleCanvasStatusChange}
    >
      <option value="all">All</option>
      <option value="notassign">Not Assigned</option>
      <option value="assign">Assigned</option>
    </select>
  </div>

  {/* Products per page selector */}
  <div className="d-flex align-items-center me-sm-3">
    <label htmlFor="products-per-page" className="me-2">Show</label>
    <select
      className="form-select my-1 my-lg-0"
      id="products-per-page"
      value={productsPerPage}
      onChange={handleProductsPerPageChange}
    >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </select>
  </div>
</div>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Product</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedProduct?.id ?? ""}
                      onChange={(e) =>
                        handleProductSelect(Number(e.target.value))
                      }
                    >
                      <option value="">Select a Product</option>
                      {products.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {selectedProduct && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mb-3"
                        onClick={handleAddPart}
                      >
                        Add Part
                      </button>
                      {parts.map((part, index) => (

                        
                        <div key={index} className="mb-3">
                          <h6>Part {index + 1}</h6>
                          <FormInput
                            name={`parts[${index}].name`}
                            label="Part Name"
                            placeholder="e.g : Front"
                            register={register}
                            errors={errors}
                            control={control}
                          />

                           <h6>Colors:</h6>
                           <div className="allcolors-canvasid">
                          {part.colors.map((color, colorIndex) => (
                            <div key={colorIndex} className="mb-3">
                              <span>{color}</span>
                              <FileUploader
                               onFileUpload={(files: any) =>
                                 handleFileUpload(files[0], index, color) 
                               }
                             />
                            </div>
                          ))}
                          </div>




                          <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}
  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}
  className="mb-3"
/>

{/* Conditionally Render Screen Printing Design & Guideline Areas */}
{part.screenPrinting && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                  
                    {part.screenPrintingData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center mt-2">
  <button
    type="button"
    className="btn btn-danger btn-sm"
    onClick={() => handleRemoveGuideBox(index, boxIndex, 'screenPrintingData')}
  >
    Remove Guidebox
  </button>
 
</div>
                        </div>
                      )
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'screenPrintingData')}
                    >
                      Add Guidebox
                    </button>
            

  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{part.embroidery && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    <div style={{ display: "none" }}>
                    {part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                value={0}
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    {/* <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index)}
                    >
                      Add Guidebox
                    </button> */}
                    </div>
                   

  </>
)}





                          
                         


                         
                        </div>
                      ))}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ToolApparel;
