import React, { useState, useEffect, ChangeEvent } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import Table from "../../../components/Table";
import PageTitle from "../../../components/PageTitle";


interface Order {
    id: number;
    name: string;
    productCanvasId: number | null;
    productImages2?: any;
  }

interface TableRow {
    id: number;
    name: string;
    parts: string; // Adjust if parts is an object instead of a string
  }
const CanvasAssignDetail = () => {
    const [orderList, setOrderList] = useState<Order[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [assignData, setAssignData] = useState<Record<number, string>>({});
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null); // Editable row ID
  const [selectedAssignId, setSelectedAssignId] = useState<number | null>(null);

  const fetchOrders = async (page: number) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend.inkox.com/api/product?page=${page}&per_page=10&assignproduct=assign`
      );
      if (!response.ok) throw new Error("Failed to fetch orders");
      const data = await response.json();
      setOrderList(data.data);
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };




  const fetchAssignData = async (id: number) => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/tool-apparels/${id}`);
      if (!response.ok) throw new Error("Failed to fetch assignId data");
      const data = await response.json();
      setAssignData((prev) => ({ ...prev, [id]: data.name }));
    } catch (error) {
      console.error("Error fetching assignId data:", error);
    }
  };

  // Fetch table data

    const fetchTableData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/tool-apparels");
        if (!response.ok) throw new Error("Failed to fetch table data");
        const data: TableRow[] = await response.json();
        setTableData(data);
        // Fetch assignId names for each row
        data.forEach((row: any) => {
          if (row.id && !assignData[row.id]) fetchAssignData(row.id);
        });
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };




    // Fetch orders on component mount and page change
    useEffect(() => {
        fetchOrders(currentPage);
        fetchTableData();
      }, [currentPage]);

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };



  const handleEditClick = (rowId: number, assignId: number | null) => {
    setEditRowId(rowId);
    setSelectedAssignId(assignId);
  };

  // Handle save button click
  const handleSaveClick = async (rowId: number) => {
    try {
      // Send the updated productCanvasId to the API
      const response = await fetch(`https://backend.inkox.com/product_update/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productCanvasId: selectedAssignId, // The updated assignId
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update the productCanvasId");
      }
  
      // Update the orderList locally after a successful API call
      const updatedList = orderList.map((item) =>
        item.id === rowId ? { ...item, productCanvasId: selectedAssignId } : item
      );
      setOrderList(updatedList);
  
      setEditRowId(null); // Exit edit mode
    } catch (error) {
      console.error("Error updating productCanvasId:", error);
      alert("An error occurred while updating the product.");
    }
  };

  // Handle dropdown selection change
  const handleAssignIdChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAssignId(Number(event.target.value));
  };

  const columns = [
    {
      Header: "Product Id",
      accessor: "id",
      Cell: ({ row }: { row: any }) => (
        <strong>{row.original.id || "N/A"}</strong>
      ),
    },
    {
      Header: "Product Name",
      accessor: "name",
      Cell: ({ row }: { row: any }) =>
        row.original.name || "Product name not found",
    },
    {
        Header: "Product Image",
        accessor: "productImage",
        Cell: ({ row }: { row: any }) => {
          // Assuming `productImages2` contains the color and front image mapping
          const productImages2 = row.original.productImages2;
          const firstColorKey = productImages2 ? Object.keys(productImages2)[0] : null; // Get the first color key
          const frontImageUrl =
            firstColorKey && productImages2[firstColorKey]?.front
              ? `https://backend.inkox.com/${productImages2[firstColorKey].front}`
              : null;
      
          return (
            <>
              {frontImageUrl ? (
                <img
                  src={frontImageUrl}
                  alt="Front Product"
                  style={{ width: "80px", height: "80px", marginRight: "5px" }}
                />
              ) : (
                <span>No Image Available</span>
              )}
            </>
          );
        },
      },
    // {
    //   Header: "Assign Id",
    //   accessor: "assignid",
    //   Cell: ({ row }: { row: any }) =>
    //     row.original.productCanvasId || "N/A",
    // },

    {
        Header: "Assign Id",
        accessor: "assignid",
        Cell: ({ row }: { row: any }) => {
          const id = row.original.productCanvasId;
          const matchedRow = tableData.find((item: TableRow) => item.id === id);
          const name = matchedRow ? matchedRow.name : "Name not found";
    
          return editRowId === row.original.id ? (
            <Form.Select
              value={selectedAssignId || ""}
              onChange={handleAssignIdChange}
            >
              <option value="">Select Assign Id</option>
              {tableData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.id} - {item.name}
                </option>
              ))}
            </Form.Select>
          ) : (
            `${id || "N/A"} - ${name}`
          );
        },
      },
      {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ row }: { row: any }) => {
          const isEditing = editRowId === row.original.id;
    
          return isEditing ? (
            <Button
              className="action-icon"
              onClick={() => handleSaveClick(row.original.id)}
            >
              <i className="mdi mdi-content-save"></i>
            </Button>
          ) : (
            <Button
              className="action-icon"
              onClick={() =>
                handleEditClick(row.original.id, row.original.productCanvasId)
              }
            >
              <i className="mdi mdi-pencil"></i>
            </Button>
          );
        },
      },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Orders", path: "/apps/ecommerce/orders", active: true },
        ]}
        title={"Canvas Assign Detail"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center mb-3">
                <Col>
                  <h4 className="header-title">Orders</h4>
                </Col>
              </Row>

              {loading ? (
                <p>Loading...</p>
              ) : (
                <Table
                  columns={columns}
                  data={orderList}
                  pageSize={10}
                  isSortable={true}
                  pagination={false} // Disable internal pagination as we handle it externally
                />
              )}

              {/* Pagination */}
              <div className="d-flex justify-content-between align-items-center mt-4">
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CanvasAssignDetail;
