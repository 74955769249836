import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// Order column render function
const OrderColumn = ({ row }: { row: any }) => {
  return (
    <Link to="/apps/ecommerce/order/details" className="text-body fw-bold">
      {row.original.id}
    </Link>
  );
};

// OrderDate column render function
const OrderDateColumn = ({ row }: { row: any }) => {
  return <>{new Date(row.original.created_at).toLocaleDateString()}</>;
};

// Total column render function
const TotalColumn = ({ row }: { row: any }) => {
  return <>${row.original.payment_amount}</>;
};

// PaymentStatus column render function
const PaymentStatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success":
            row.original.payment_status === "succeeded",
          "bg-soft-danger text-danger":
            row.original.payment_status === "failed",
          "bg-soft-info text-info": row.original.payment_status === "pending",
        })}
      >
        {row.original.payment_status === "succeeded" && (
          <i className="mdi mdi-check me-1"></i>
        )}
        {row.original.payment_status === "failed" && (
          <i className="mdi mdi-cancel me-1"></i>
        )}
        {row.original.payment_status === "pending" && (
          <i className="mdi mdi-timer-sand me-1"></i>
        )}
        {row.original.payment_status}
      </span>
    </h5>
  );
};

// PaymentMethod column render function
const PaymentMethodColumn = ({ row }: { row: any }) => {
  return <>{row.original.payment_method}</>;
};

// Status column render function
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-success": row.original.orderStatus === "Delivered",
          "bg-danger": row.original.orderStatus === "pending",
          "bg-info": row.original.orderStatus === "Shipped",
          "bg-warning": row.original.orderStatus === "Processing",
        })}
      >
        {row.original.orderStatus}
      </span>
    </h5>
  );
};



const ActionColumn = ({ row, onStatusChange }: { row: any; onStatusChange: (orderId: number, status: string) => void }) => {
  const handleViewClick = async () => {
    const response = await fetch(
      `https://backend.inkox.com/api/orderStatus/${row.original.orderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ orderStatus: "Ready For Dispatched" }),
      }
    );

    if (response.ok) {
      alert("This Order Is Ready For Dispatched");
      onStatusChange(row.original.orderId, "Ready For Dispatched");
    } else {
      alert("Error While Updating");
    }
  };

  return (
    <Button className="btn btn-primary" onClick={handleViewClick}>
      Done By Production
    </Button>
  );
};

const DelColumn = ({
  row,
  orderStatuses,
}: {
  row: any;
  orderStatuses: Record<number, string>;
}) => {
  const handleViewClick = async () => {
    const response = await fetch(
      `https://backend.inkox.com/api/orderStatus/${row.original.orderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          orderStatus: "Departed from Inkox/with delivery partner",
        }),
      }
    );

    if (response.ok) {
      alert("This Order Is Departed from Inkox/with delivery partner");
    } else {
      alert("Error While Updating");
    }
  };

  // Check if the current row's status matches the conditions
  const status = orderStatuses[row.original.orderId]; // Access orderStatus by orderId

  return status === "Ready For Dispatched" ||
    status === "Departed from Inkox/with delivery partner" ? (
    <Button className="btn btn-primary" onClick={handleViewClick}>
      Delivery PickUp
    </Button>
  ) : null;
};




const getColumns = (handleEditClick: (id: number) => void, handleDeleteClick: (id: number) => void, onStatusChange: (orderId: number, status: string) => void, orderStatuses: Record<number, string>) => [
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ row }: { row: any }) => <>{row.original.id}</>,
    },

    {
        Header: "orderId",
        accessor: "orderId",
        Cell: ({ row }: { row: any }) => <>{row.original.orderId}</>,
      },
    {
      Header: "Work Order",
      accessor: "work_order",
      Cell: ({ row }: { row: any }) => (
        <a href={`https://backend.inkox.com/storage/${row.original.work_order}`} target="_blank" rel="noopener noreferrer">
          View Work Order
        </a>
      ),
    },
    {
      Header: "Purchase Order",
      accessor: "poc",
      Cell: ({ row }: { row: any }) => (
        <a href={`https://backend.inkox.com/storage/${row.original.poc}`} target="_blank" rel="noopener noreferrer">
          View Purchase Order
        </a>
      ),
    },
    {
      Header: "Mock",
      accessor: "mock_ss",
      Cell: ({ row }: { row: any }) => {
        const images = JSON.parse(row.original.mock_ss || "[]");
        return (
          <>
            {images.map((url: string, index: number) => (
              <img
                key={index}
                src={url}
                alt={`Mock ${index + 1}`}
                style={{ width: "100px", height: "100px", marginRight: "5px" }}
              />
            ))}
          </>
        );
      },
    },
    {
      Header: "Designer Final",
      accessor: "designer_final",
      Cell: ({ row }: { row: any }) => {
        const images = JSON.parse(row.original.designer_final || "[]");
        return (
          <>
            {images.map((url: string, index: number) => (
              <img
                key={index}
                src={`https://backend.inkox.com/storage/designer_portal/designer_upload_main/${url}`}
                alt={`Designer Final ${index + 1}`}
                style={{ width: "100px", height: "100px", marginRight: "5px" }}
              />
            ))}
          </>
        );
      },
    },

    {
      Header: "Completed",
      accessor: "completed",
      Cell: ({ row }: { row: any }) => (
        <ActionColumn row={row} onStatusChange={onStatusChange} />
      ),
    },
    {
      Header: "Departed",
      accessor: "departed",
      Cell: ({ row }: { row: any }) => (
        <DelColumn row={row} orderStatuses={orderStatuses} />
      ),
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ row }: { row: any }) => (
        <>{new Date(row.original.created_at).toLocaleDateString()}</>
      ),
    },
  ];
  

// Pagination size options
const sizePerPageList = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "50",
    value: 50,
  },
];

const Production = () => {
  const [orderList, setOrderList] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null); // Selected row id
  const [paymentStatus, setPaymentStatus] = useState<string>(""); // Selected payment status
  const [orderStatus, setOrderStatus] = useState<string>(""); // Selected order status
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState<number | null>(null);
   const [orderStatuses, setOrderStatuses] = useState<Record<number, string>>({});

  const handleStatusChange = (orderId: number, status: string) => {
    setOrderStatuses((prevStatuses) => ({
      ...prevStatuses,
      [orderId]: status,
    }));
  };

  // Fetch the orders from API using fetch
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/qc-portal");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrderList(data);
  
        // Fetch order statuses from `place-orders` API for each order
        const statuses: Record<number, string> = {};
        for (const order of data) {
          const status = await fetchOrderStatus(order.orderId); // Ensure `order.orderId` is correct
          statuses[order.orderId] = status; // Use `order.orderId` as the key
        }
        setOrderStatuses(statuses); // Set all fetched statuses
        console.log("Fetched orderStatuses:", statuses); // Debug log
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
  
    fetchOrders();
  }, []);
  


  const fetchOrderStatus = async (orderId: number): Promise<string> => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/place-orders/${orderId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch order status for order ID: ${orderId}`);
      }
      const data = await response.json();
      console.log(`Fetched status for order ${orderId}:`, data.orderStatus); // Debug log
      return data.orderStatus; // Ensure this matches the API's response
    } catch (error) {
      console.error(`Error fetching status for order ${orderId}:`, error);
      return ""; // Return an empty string in case of an error
    }
  };
  


  // Handle edit button click
  const handleEditClick = (orderId: number) => {
    const selectedOrder: any = orderList.find((order: any) => order.id === orderId);
    if (selectedOrder) {
      setSelectedOrderId(orderId);
      setPaymentStatus(selectedOrder.payment_status);
      setOrderStatus(selectedOrder.orderStatus);
      setShowModal(true);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrderId(null);
  };

  // Handle form submission to update payment and order status
  const handleUpdateStatus = async () => {
    if (selectedOrderId) {
      try {
        const response = await fetch(`https://backend.inkox.com/api/place-orders/${selectedOrderId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_status: paymentStatus,
            orderStatus: orderStatus,
          }),
        });

        if (response.ok) {
          alert("Order updated successfully!");
          setShowModal(false);
        } else {
          alert("Failed to update the order.");
        }
      } catch (error) {
        console.error("Error updating order:", error);
        alert("An error occurred while updating the order.");
      }
    }
  };

  const handleDeleteClick = (orderId: number) => {
    setDeleteOrderId(orderId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteOrderId(null);
  };

  const handleDelete = async () => {
    if (deleteOrderId) {
      try {
        const response = await fetch(`https://backend.inkox.com/api/place-orders/${deleteOrderId}`, {
          method: "DELETE",
        });

        if (response.ok) {
          alert("Order deleted successfully!");
          setOrderList(orderList.filter((order: any) => order.id !== deleteOrderId));
          handleCloseDeleteModal();
        } else {
          alert("Failed to delete the order.");
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        alert("An error occurred while deleting the order.");
      }
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Production", path: "/apps/ecommerce/prod", active: true },
        ]}
        title={"Production"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={8}>
                  <form className="row gy-2 gx-2 align-items-center justify-content-lg-start justify-content-between">
                    <div className="col-auto">
                      {/* <div className="d-flex align-items-center w-auto">
                        <label htmlFor="status-select" className="me-2">
                          Status
                        </label>
                        <select className="form-select" id="status-select">
                          <option value="All">All</option>
                          <option value="Paid">Paid</option>
                          <option value="Authorization">
                            Awaiting Authorization
                          </option>
                          <option value="Failed">Payment failed</option>
                          <option value="Unpaid">Unpaid</option>
                        </select>
                      </div> */}
                    </div>
                  </form>
                </Col>

                {/* <Col lg={4}>
                  <div className="text-lg-end mt-xl-0 mt-2">
                    <Button className="btn btn-danger mb-2 me-2">
                      <i className="mdi mdi-basket me-1"></i> Add New Order
                    </Button>
                    <Button className="btn btn-light mb-2">Export</Button>
                  </div>
                </Col> */}
              </Row>

              <Table
                columns={getColumns(handleEditClick, handleDeleteClick, handleStatusChange, orderStatuses)} // Pass the edit button handler to the table
                data={orderList}
                pageSize={10}
                sizePerPageList={sizePerPageList} // Pagination size options
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for updating status */}
      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Payment Status</Form.Label>
              <Form.Select
                value={paymentStatus}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setPaymentStatus(e.target.value)}
              >
                <option value="">Select Payment Status</option>
                <option value="succeeded">Succeeded</option>
                <option value="failed">Failed</option>
                <option value="pending">Pending</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Order Status</Form.Label>
              <Form.Select
                value={orderStatus}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setOrderStatus(e.target.value)}
              >
                <option value="">Select Order Status</option>
                <option value="Delivered">Delivered</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Shipped">Shipped</option>
                <option value="Processing">Processing</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateStatus}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Production;
