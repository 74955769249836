import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import WorkPage from "./WorkPage";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PurchaseOrder from "./Po";

const QcDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderList = location.state?.order;

  const [designerUploaded, setDesignerUploaded] = useState<File[]>([]);
  const [designerUploadMain, setDesignerUploadMain] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [designerMainFiles, setDesignerMainFiles] = useState<string[]>(
    JSON.parse(orderList?.designer_upload_main || "[]")
  );

  const handleFileSelect = (file: File, isSelected: boolean) => {
    setSelectedFiles((prevSelected) =>
      isSelected
        ? [...prevSelected, file]
        : prevSelected.filter((selectedFile) => selectedFile.name !== file.name)
    );
  };


  const handleFileUploadClick = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected to upload.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await fetch(
        `https://backend.inkox.com/api/designer/${orderList.id}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const responseData = await response.json();

      if (responseData.success) {
        const uploadedFiles = responseData.files; // Assume API returns file paths
        setDesignerMainFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        setSelectedFiles([]); // Clear selected files after upload
        alert("Files uploaded successfully!");
      } else {
        throw new Error("File upload was unsuccessful.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files.");
    }
  };
  
  
  
  
  
  
  
  if (!orderList) {
    return <p>No order data available</p>;
  }


  const handleFileClick = async () => {
    if (!selectedFiles.length) {
      alert("No files selected to upload.");
      return;
    }
  
    const formData = new FormData();
   
    try {
      // Generate PDF for `work_order`
     const orderId = JSON.parse(orderList.order_data[0]);
      const statusResponse = await fetch(
        `https://backend.inkox.com/api/orders/${orderId?.id}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ Status: "Approved" }),
        }
      );
  
      if (!statusResponse.ok) {
        throw new Error(
          `Failed to update status: ${statusResponse.statusText}`
        );
      }

      const Response = await fetch(
        `https://backend.inkox.com/api/orderStatus/${orderId?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ orderStatus: "On Production" }),
        }
      );
  
      if (!Response.ok) {
        throw new Error(
          `Failed to update status: ${Response.statusText}`
        );
      }

      const orderData = JSON.parse(orderList.order_data[0]);
      const mockSS = orderData.cartData[0]?.imagePreviews.map((preview: any) => preview.preview) || [];

      formData.append("orderId", orderId.id);
      const workOrderElement = document.querySelector(".work-orders-container") as HTMLElement;

      if (workOrderElement) {
        const canvas = await html2canvas(workOrderElement, {
          scale: 1, // Improves resolution
          useCORS: true, // Ensures cross-origin images are included
        });
      
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
      
        const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 page width in mm
        const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page height in mm
      
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
      
        // Calculate the aspect ratio for scaling
        const imgHeight = (pdfWidth * canvasHeight) / canvasWidth; // Image height in PDF units
        let yPosition = 0;
      
        while (yPosition < canvasHeight) {
          // Create a portion of the canvas for the current page
          const sectionCanvas = document.createElement("canvas");
          sectionCanvas.width = canvasWidth;
          sectionCanvas.height = (pdfHeight * canvasWidth) / pdfWidth; // Height in canvas units that fits one PDF page
      
          const ctx = sectionCanvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(
              canvas,
              0,
              yPosition,
              canvasWidth,
              sectionCanvas.height, // Source height to capture
              0,
              0,
              sectionCanvas.width,
              sectionCanvas.height // Destination height
            );
          }
      
          const sectionImgData = sectionCanvas.toDataURL("image/png");
      
          if (yPosition > 0) {
            pdf.addPage();
          }
          pdf.addImage(sectionImgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      
          yPosition += sectionCanvas.height; // Move to the next section
        }
      
        // const pdfBlob = pdf.output("blob");
      
        const pdfBlob = pdf.output("blob");
  const pdfFileName = `${orderList.id}_work_order.pdf`; // Construct the file name using the orderId
  const pdfFile = new File([pdfBlob], pdfFileName, { type: "application/pdf" });
        formData.append("work_order", pdfFile);
      }
      
      


      const pOrderElement = document.querySelector(".purchase-order-container") as HTMLElement;
      if (pOrderElement) {


        const canvas = await html2canvas(pOrderElement, {
          scale: 1, // Improves resolution
          useCORS: true, // Ensures cross-origin images are included
        });
      
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
      
        const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 page width in mm
        const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page height in mm
      
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
      
        // Calculate the aspect ratio for scaling
        const imgHeight = (pdfWidth * canvasHeight) / canvasWidth; // Image height in PDF units
        let yPosition = 0;
      
        while (yPosition < canvasHeight) {
          // Create a portion of the canvas for the current page
          const sectionCanvas = document.createElement("canvas");
          sectionCanvas.width = canvasWidth;
          sectionCanvas.height = (pdfHeight * canvasWidth) / pdfWidth; // Height in canvas units that fits one PDF page
      
          const ctx = sectionCanvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(
              canvas,
              0,
              yPosition,
              canvasWidth,
              sectionCanvas.height, // Source height to capture
              0,
              0,
              sectionCanvas.width,
              sectionCanvas.height // Destination height
            );
          }
      
          const sectionImgData = sectionCanvas.toDataURL("image/png");
      
          if (yPosition > 0) {
            pdf.addPage();
          }
          pdf.addImage(sectionImgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      
          yPosition += sectionCanvas.height; // Move to the next section
        }
        const pdfBlob = pdf.output("blob");
  
        // Convert Blob to File
        // const pdfBlob = pdf.output("blob");
        const pdfFileName = `${orderList.id}_purchase_order.pdf`; // Construct the file name using the orderId
        const pdfFile = new File([pdfBlob], pdfFileName, { type: "application/pdf" });
        formData.append("poc", pdfFile);
        // const pdfFile = new File([pdfBlob], "purchase_order.pdf", { type: "application/pdf" });
        // formData.append("poc", pdfFile);
        }
  
      // Append additional data
      // formData.append("poc", "Point of Contact");
      mockSS.forEach((item: string, index: number) => {
        formData.append(`mock_ss[${index}]`, item); // Send as array
      });
      

      selectedFiles.forEach((file: File, index: number) => {
        formData.append(`designer_final[${index}]`, file.name); // Use file.name as a string
      });
  
  
      const response = await fetch("https://backend.inkox.com/api/qc-portal", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload files: ${response.statusText}`);
      }
  
      const responseData = await response.json();
  
      if (responseData.success) {
        alert("Files and work order uploaded successfully!");
        setSelectedFiles([]);
      } else {
        throw new Error("File upload was unsuccessful.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files.");
    }
  };
  
  

  // Parse `order_data`
  const orderData = JSON.parse(orderList.order_data[0]);
  const designerUploadedFiles = JSON.parse(orderList.designer_uploaded || "[]");
  // const designerMainFiles = JSON.parse(orderList.designer_upload_main || "[]");

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/order/details" },
          { label: "Qc Detail", path: "/apps/ecommerce/order/details", active: true },
        ]}
        title={`Qc Detail - ${orderList.id}`}
      />
    <WorkPage orderId={orderData.id}/>
    <br/>
    <PurchaseOrder orderId={orderData.id}/>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Items</h4>
              <div className="table-responsive">
                <table className="table table-bordered table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Product Name</th>
                      <th>Product Color</th>
                      <th>Image</th>
                      <th>Clipart Images</th>
                      <th>Uploaded Images</th>
                      <th>Total Quantity</th>
                      <th>Quantity With Size</th>
                      <th>Fonts</th>
                      <th>No Of Colors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.cartData.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productColor}</td>
                        <td>
                          {item.imagePreviews.map((img: any, imgIndex: number) => (
                            <img
                              key={imgIndex}
                              src={img.preview}
                              alt={img.part}
                              style={{ width: "50px", height: "50px", marginRight: "5px" }}
                            />
                          ))}
                        </td>
                        <td>
                          {item.clipArtData.map((clipart: any, clipIndex: number) => (
                            <div key={clipIndex}>
                              <p>{clipart.part}</p>
                              {/* <p>{clipart.clipartPath}</p> */}
                              <img
                                src={clipart.clipartPath || ""}
                                alt={`Clipart ${clipart.part}`}
                                style={{ width: "50px", height: "50px", marginRight: "5px" }}
                              />
                            </div>
                          ))}
                        </td>
                        <td>
  {item.uploadedImageData && item.uploadedImageData.length > 0 ? (
    Array.from(
      new Map(
        item.uploadedImageData.map((img: any) => [img.part, img]) // Use `part` as the unique key
      ).values() // Get unique images based on `part`
    ).map((uniqueImg: any, uniqueIndex: number) => (
      <div key={uniqueIndex} style={{ marginBottom: "10px" }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{uniqueImg.part}</p>
        <img
          src={uniqueImg.preview}
          alt={`Product ${uniqueImg.part}`}
          style={{ width: "50px", height: "50px", marginRight: "5px" }}
        />
      </div>
    ))
  ) : (
    <div style={{ fontStyle: "italic", color: "gray" }}>
      No Image Is Available
    </div>
  )}
</td>



                        <td>{item.totalQuantity}</td>
                        <td>
                          {Object.entries(item.quantities).map(([size, qty]) => (
                            <div key={size}>
                              {size}: {qty}
                            </div>
                          ))}
                        </td>
                        <td>{item.selectedFonts.join(", ") || "None"}</td>
                        <td>
  {Object.entries(item.inkColors as Record<string, string[]>).map(([part, colors]) => (
    <div key={part} style={{ marginBottom: "10px" }}>
      <p style={{ fontWeight: "bold", margin: "0" }}>{part}</p>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        {colors.length > 0 ? (
          colors.map((color, colorIndex) => (
            <div
              key={`${part}-${colorIndex}`}
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: color,
                border: "1px solid #000",
                marginRight: "5px",
              }}
            ></div>
          ))
        ) : (
          <span style={{ fontStyle: "italic", color: "gray" }}>No colors</span>
        )}
      </div>
    </div>
  ))}
</td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Designer Uploaded Files</h4>
              {designerUploadedFiles.map((file: string, index: number) => (
                <a
                  key={index}
                  href={`http://127.0.0.1:8000/storage/${file}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block" }}
                >
                  File {index + 1}
                </a>
              ))}
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Designer Main Files</h4>
              {designerMainFiles.map((file: string, index: number) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    type="checkbox"
                    className="me-2"
                    onChange={(e) =>
                      handleFileSelect(
                        new File([file], file.split("/").pop() || "unknown"),
                        e.target.checked
                      )
                    }
                  />
                  <a
                    href={`https://backend.inkox.com/storage/${file}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    File {index + 1}
                  </a>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
      <Col lg={12} className="text-center">
         <FileUploader  onFileUpload={setSelectedFiles}/>
        </Col>
        </Row>

      <Row className="d-flex justify-content-center mb-2">
        <Col lg={6} className="text-center">
          <Button
            className="btn btn-warning"
            onClick={handleFileUploadClick}
          >
            Upload
          </Button>
        </Col>
        <Col lg={6} className="text-center">
          <Button
            className="btn btn-success"
            onClick={handleFileClick}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}


export default QcDetail;