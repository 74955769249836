import React, { FC, useState, useEffect } from 'react';
import "./workorderpage.css"
import pantoneColors from './pantone-coated.json';
import { colorHexMap } from './colorlibrarygarments';
import logo from "../../../assets/images/ink_logo.png";
import Header from './Header';
import Footer from './Footer';

interface WorkOrder {
  id: number;
  orderStatus: string;
  payment_status: string;
  payment_amount: number;
  payment_currency: string;
  cartData: Array<{
    id: number;
    productName: string;
    productColor: string;
    imagePreviews: Array<{ preview: string; part: string }>;
    inkColors: Record<string, string[]>;
    uploadedImageData: Array<{ preview: string; part: string }>;
    clipArtData: Array<{ clipartPath: string; part: string }>;
    created_at: string;
    orderType: string;
    canvasId: string;
    total_price_with_delivery: string;
    Delivery_date: Record<string, string>;
    quantities: Record<string, number>;
    totalQuantity: number;
  }>;
}

interface WorkOrderPageProps {
  orderId: number;
}

const WorkPage: FC<WorkOrderPageProps> = ({ orderId }) => {
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(orderId);
  const [hoveredColor, setHoveredColor] = useState<string | null>(null);


  useEffect(() => {
    const fetchWorkOrders = async (): Promise<void> => {
      try {
        const response = await fetch('https://backend.inkox.com/api/place-orders');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: WorkOrder[] = await response.json();
        setWorkOrders(data);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkOrders();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleIdChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedId(Number(e.target.value));
  };

  const findClosestColor = (hex: string): { pantone: string; hex: string } => {
    const hexToRgb = (h: string): number[] => h.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    const colorDistance = (rgb1: number[], rgb2: number[]): number =>
      Math.sqrt(rgb1.reduce((sum, val, i) => sum + Math.pow(val - rgb2[i], 2), 0));

    const targetRgb = hexToRgb(hex);
    let closestColor = { pantone: 'unknown', hex: '#FFFFFF' };
    let smallestDistance = Infinity;

    pantoneColors.forEach((color) => {
      const pantoneRgb = hexToRgb(color.hex);
      const distance = colorDistance(targetRgb, pantoneRgb);
      if (distance < smallestDistance) {
        closestColor = color;
        smallestDistance = distance;
      }
    });
    return closestColor;
  };

  const getColorInfo = (color: string | null): { pantone: string; hex: string } => {
    if (!color) return { pantone: 'unknown', hex: '#FFFFFF' };

    let hex = color.startsWith("rgb") ? rgbToHex(color) : color;

    if (!hex) return { pantone: 'unknown', hex: '#FFFFFF' };

    const exactMatch = pantoneColors.find((c) => c.hex.toLowerCase() === (hex?.toLowerCase() || ''));
    if (exactMatch) return exactMatch;

    return findClosestColor(hex);
  };

  const rgbToHex = (rgb: string): string | null => {
    const result = rgb.match(/\d+/g);
    if (result && result.length >= 3) {
      const [r, g, b] = result.map(Number);
      return (
        "#" +
        [r, g, b]
          .map((x) => x.toString(16).padStart(2, "0"))
          .join("")
      );
    }
    return null;
  };

  const mergeAllColors = (inkColors: Record<string, string[]> | undefined): string[] => {
    const mergedColors = new Set<string>();
    Object.values(inkColors || {}).forEach((colors) => {
      if (Array.isArray(colors)) {
        colors.forEach((color) => mergedColors.add(color));
      }
    });
    return Array.from(mergedColors);
  };

  const filteredWorkOrders = selectedId
    ? workOrders.filter((order) => order.id === selectedId)
    : workOrders;

  return (
    <div id="work-orders-container" className="work-orders-container" style={{ padding: '20px' }}>
{/* 
  <Header/> */}


   
{filteredWorkOrders.map((order) => {
const allCartItems = order.cartData;
return (
  <div key={order.id} className="work-order" style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px', borderRadius: '5px' }}>
   
       <h1 style={{ textAlign: 'center', color: '#d32f2f' }}>Work Orders</h1> 
       <h2 style={{ textAlign: 'center', color: '#333' }}>Order ID: {order.id}</h2>




    {order.cartData.map((item, itemIndex) => {
      const mergedColors = mergeAllColors(item.inkColors);
      // const allCartItems = order.cartData;


      return (
        <div key={item.id} style={{ marginBottom: '20px' }}>
       
          <h3 className="product-name-work" style={{ textAlign: 'center', marginBottom: '10px' }}>{item.productName}</h3>

      
          <table style={{ width: '100%' }}>
            <tbody>
              <tr className="main-color-image">
                <td style={{ width: '70%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', overflowX: 'auto' }}>
  {item.imagePreviews?.map((preview, index) => (
    <img
      key={index}
      src={preview.preview}
      alt={`${preview.part} preview`}
      style={{
        height: '100px', 
        objectFit: 'contain',
        border: '1px solid #ccc',
        borderRadius: '5px',
      }}
    />
  ))}
</div>

                </td>
                <td style={{ textAlign: 'center', marginBottom: '20px' }}>
               
                  <h3>Ink Colors</h3>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
                    <div style={{ border: '1px solid #ddd', width: '100%', padding: '8px', textAlign: 'center' }}>
                      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Color Preview</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Hex Code</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Suggested Pantone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mergedColors.map((color, index) => {
                            const colorInfo = getColorInfo(color);
                            return (
                              <tr key={index}>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '25px',
                                      height: '25px',
                                      backgroundColor: color || '#FFFFFF',
                                      border: '1px solid #000',
                                    }}
                                    onMouseEnter={() => setHoveredColor(colorInfo.hex)}
                                    onMouseLeave={() => setHoveredColor(null)}
                                  ></div>
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{color}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                  {colorInfo.pantone}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <table style={{ width: '48%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Created At</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {item.created_at?.split('T')[0] || 'Not Available'}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Printing Type</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {item.orderType || 'Not Available'}
                  </td>
                </tr>
               
              </tbody>
            </table>

            <table style={{ width: '48%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <tbody>

                 <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Canvas ID</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {item.canvasId || 'Not Available'}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Order ID</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {order.id}
                  </td>
                </tr>
                {/* <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Order Status</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{order.orderStatus}</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Payment Status</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{order.payment_status}</td>
                </tr> */}
                {/* <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Payment Amount</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
                    {item.total_price_with_delivery} 
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

<div style={{ display: "flex", gap: "30px", justifyContent: "center", marginBottom: "20px" }}>

  <div style={{ textAlign: "center" }}>
    <h3>Uploaded Images</h3>
    {item.uploadedImageData.length > 0 ? (
      <div style={{ display: "flex", flexWrap: "nowrap", gap: "10px", justifyContent: "center", overflowX: "auto" }}>
        {item.uploadedImageData.map((uploaded, index) => (
          <img
            key={index}
            src={uploaded.preview}
            alt={`Uploaded ${uploaded.part}`}
            style={{
              width: "50px",
              height: "50px",
              objectFit: "contain",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ))}
      </div>
    ) : (
      <p>No uploaded images</p>
    )}
  </div>

  <div style={{ textAlign: "center" }}>
    <h3>Clip Art Data</h3>
    {item.clipArtData.length > 0 ? (
      <div style={{ display: "flex", flexWrap: "nowrap", gap: "10px", justifyContent: "center", overflowX: "auto" }}>
        {item.clipArtData.map((clip, index) => (
          <div key={index} style={{ textAlign: "center", minWidth: "60px" }}>
            <img
              src={clip.clipartPath}
              alt={`Clip Art on ${clip.part}`}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "contain",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <p style={{ fontSize: "12px", margin: "5px 0 0" }}>
              <strong>Part:</strong> {clip.part}
            </p>
          </div>
        ))}
      </div>
    ) : (
      <p>No clip art data</p>
    )}
  </div>
</div>
         


          <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '5px', textAlign: "center" }}>
  <h5>Delivery Date:</h5>
  {Object.entries(item.Delivery_date || {}).length > 0 ? (
    <ul style={{ padding: '0', margin: '0', listStyle: 'none' }}>
      {Object.entries(order.cartData[0]?.Delivery_date || {}).map(([key, value]) => (
        <li key={key} style={{ fontSize: '16px', marginBottom: '5px' }}>
          <strong>{key}:</strong> {value}
        </li>
      ))}
    </ul>
  ) : (
    <p>Not Available</p>
  )}
</div>
        </div>

        
      );
    })}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {/* <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Garments</th> */}
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Style</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Color</th>
              {['S', 'M', 'L', 'XL', '2XL', '3XL'].map((size) => (
                <th key={size} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{size}</th>
              ))}
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {allCartItems.map((item) => (
              <tr key={item.id}>
                {/* <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Jackets</td> */}
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.productName}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '25px',
                      height: '25px',
                      backgroundColor: item.productColor,
                      border: '1px solid #000',
                    }}
                  ></span>
                </td>
                {['S', 'M', 'L', 'XL', '2XL', '3XL'].map((size) => (
                  <td key={size} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                    {item.quantities[size] || 0}
                  </td>
                ))}
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.totalQuantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          <a rel="noreferrer" target="_blank" href={`http://127.0.0.1:8000/storage/purchase_orders/${orderId}_purchase_order.pdf`}>
          {`http://127.0.0.1:8000/storage/purchase_orders/${orderId}_purchase_order.pdf`}
          </a>
        </p>
  </div>

  );
})}

 {/* <Footer/> */}

  </div>
  );
};  

export default WorkPage;